import { createContext } from "react";

export const MyContext = createContext({
  userClient: "",
});
export const distributorIDContext = createContext({
  distributorID: "",
});
export const clientIDContext = createContext({
  clientID: "",
}); 
