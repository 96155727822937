import React, { useEffect, useState, createContext } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Divider, Alert } from "antd";
import { MobileOutlined, LockOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import {
  signIn,
  showLoading,
  showAuthMessage,
  hideAuthMessage,
  signInWithGoogle,
  signInWithFacebook,
} from "store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN, USER_DATA, USER_DATA_TOKEN } from "constants/AuthConstant";
import service from "auth/FetchInterceptor";
import { useDispatch } from "react-redux";
import { authenticated } from "store/slices/authSlice";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useContext } from "react";
import { API_ENDPOINTS } from "constants/ApiEndpoint";
import { USER_TYPE } from "constants/UserConstant";

export const LoginContext = createContext({
  user: "",
  user_id: "",
});
export const LoginForm = (props) => {
  const navigate = useNavigate();

  const {
    hideAuthMessage,
    showLoading,
    signInWithGoogle,
    signInWithFacebook,
    signIn,
    token,
    redirect,
    showMessage,
    message,
    allowRedirect = true,
  } = props;

  const onLogin = (values) => {
    showLoading();
    signIn(values);
  };

  const onGoogleLogin = () => {
    showLoading();
    signInWithGoogle();
  };

  const onFacebookLogin = () => {
    showLoading();
    signInWithFacebook();
  };

  const [title, setTitle] = useState("Sign In");
  const [loading, setLoading] = useState(false);

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const userData = useContext(LoginContext);

  const validateLoginInput = () => {
    if (!login.email || !login.password) {
      return "Email and password are required.";
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(login.email)) {
      return "Please enter a valid email address.";
    }

    return null;
  };

  const handleClick = async () => {
    const validationError = validateLoginInput();
    if (validationError) {
      setVerificationStatus(validationError);
      return;
    }

    setLoading(true);

    try {
      const response = await service({
        method: "post",
        url: `${API_ENDPOINTS.LOGIN_USER}`,
        data: login,
      });

      if (response.status === "success") {
        setLoading(false);
        setVerificationStatus("Sign In successfully!");
        setTitle("Sign In");

        const user_data = response.data.user_data;
        const login_user = user_data.role;
        let newUserData = {};

        console.log(user_data , "user_data")

        if (login_user === USER_TYPE.CLIENT) {
          newUserData = {
            ...userData,
            user: user_data.user_name,
            user_id: user_data.id,
            clientLogin_id: user_data.clientData.id,
            token: response.data.token,
            role: login_user,
            mobile: user_data.mobile,
          };
          navigate(`${APP_PREFIX_PATH}/section/myDistributor`, {
            replace: true,
          });
        } else if (login_user === USER_TYPE.DISTRIBUTOR) {
          newUserData = {
            ...userData,
            user: user_data.user_name,
            user_id: user_data.id,
            clientLogin_id: user_data.distributorData.client_id,
            distributorLogin_id: user_data.distributorData.id,
            operatorLogin_id: user_data.operatorData.id,
            token: response.data.token,
            role: login_user,
            mobile: user_data.mobile,
          };
          navigate(`${APP_PREFIX_PATH}/section/myOperator`, {
            replace: true,
          });
        } else if (login_user === "admin") {
          newUserData = {
            ...userData,
            user: login_user,
            user_id: user_data.id,
            mobile: user_data.mobile,
          };
          navigate(`${APP_PREFIX_PATH}/section/myClient`, { replace: true });
        } else if (login_user === USER_TYPE.MASTER_ADMIN) {
          newUserData = {
            ...userData,
            user: user_data.user_name,
            user_id: user_data.id,
            token: response.data.token,
            role: login_user,
            mobile: user_data.mobile,
          };
          navigate(`${APP_PREFIX_PATH}/section/myClient`, { replace: true });
        } else if (login_user === USER_TYPE.OPERATOR) {
          newUserData = {
            ...userData,
            user: user_data.user_name,
            user_id: user_data.id,
            clientLogin_id: user_data.operatorData.client_id,
            distributorLogin_id: user_data.operatorData.distributor_id,
            operatorLogin_id: user_data.operatorData.id,
            token: response.data.token,
            role: login_user,
            mobile: user_data.mobile,
          };
          navigate(`${APP_PREFIX_PATH}/info/Policy`, { replace: true });
        }

        userData.method(newUserData);
        localStorage.setItem(USER_DATA, JSON.stringify(newUserData));
        localStorage.setItem(USER_DATA_TOKEN, response.data.token);
        localStorage.setItem(AUTH_TOKEN, response.data.token);
        dispatch(authenticated(response.data.token));
      } else {
        setVerificationStatus("Sign In failed. Please try again.");
        setError("Failed to sign in. Please try again.");
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token !== null && allowRedirect) {
      navigate(redirect);
    }
    if (showMessage) {
      const timer = setTimeout(() => hideAuthMessage(), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  return (
    <>
      <Form layout="vertical" name="login-form" onFinish={onLogin}>
        <Form.Item label="User ID (Email ID)">
          <Input
            prefix={<MobileOutlined className="text-primary" />}
            value={login.email}
            onChange={(e) => {
              setLogin({ ...login, email: e.target.value });
            }}
          />
        </Form.Item>

        <Form.Item label="Password">
          <Input.Password
            prefix={<LockOutlined className="text-primary" />}
            value={login.password}
            onChange={(e) => setLogin({ ...login, password: e.target.value })}
          />
        </Form.Item>

        <a className="text-primary" href="/auth/resetPassword">
          Forgot Password
        </a>
      </Form>

      {/* </DataContext.Provider> */}
      <Form.Item className="mt-3">
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={loading}
          onClick={title == "Sign In" ? handleClick : ""}
        >
          {title}
        </Button>
        {verificationStatus && (
          <p className="text-danger">{verificationStatus}</p>
        )}
      </Form.Item>
      <h4>
        <a href="/auth/login">
          <u>Sign in with Mobile No.</u>
        </a>
      </h4>
    </>
  );
};

LoginForm.propTypes = {
  otherSignIn: PropTypes.bool,
  showForgetPassword: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

LoginForm.defaultProps = {
  otherSignIn: true,
  showForgetPassword: false,
};

const mapStateToProps = ({ auth }) => {
  const { loading, message, showMessage, token, redirect } = auth;
  return { loading, message, showMessage, token, redirect };
};

const mapDispatchToProps = {
  signIn,
  showAuthMessage,
  showLoading,
  hideAuthMessage,
  signInWithGoogle,
  signInWithFacebook,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
