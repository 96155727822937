/** @jsxImportSource @emotion/react */
import React from 'react';
import { APP_NAME } from 'configs/AppConfig';
import { css } from '@emotion/react';
import { TEMPLATE, MEDIA_QUERIES, DARK_MODE, BORDER } from 'constants/ThemeConstant';
import { useSelector } from 'react-redux';

export default function Footer(props) {

	const currentTheme = useSelector(state => state.theme.currentTheme);

	return (
		<footer className={props.footer_align} css={css`
			height: ${TEMPLATE.FOOTER_HEIGHT}px;
			display: flex;
			margin: 0 ${TEMPLATE.LAYOUT_CONTENT_GUTTER}px;
			align-items: center;
			border-top: 1px solid ${currentTheme === 'dark' ? DARK_MODE.BORDER_BASE_COLOR : BORDER.BASE_COLOR};
			justify-content: space-between;
		
			@media ${MEDIA_QUERIES.MOBILE} {
				justify-content: center;
       			flex-direction: column;
			}
		`}>
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
			<div>
				<a className="text-gray" href="https://grameseva.com/terms-and-conditions/" target='_blank'>Terms & conditions</a>
				<span className="mx-2 text-muted"> | </span>
				<a className="text-gray" href="https://grameseva.com/privacy-policy/" target='_blank'>Privacy policy</a>
			</div>
		</footer>
	);
}

