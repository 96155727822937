import React, { useContext, useEffect, useState, createContext } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Input,
  Divider,
  Alert,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import { MobileOutlined, LockOutlined } from "@ant-design/icons";
import MyLogo from "../../../../src/assets/logo/hayoshaFinalLogo.png";
import { loginBanner } from "constants/ColumnConstants";
import "../../../common.css";
import { API_ENDPOINTS } from "constants/ApiEndpoint";
import { USER_TYPE } from "constants/UserConstant";

import PropTypes from "prop-types";
import { GoogleSVG, FacebookSVG } from "assets/svg/icon";
import CustomIcon from "components/util-components/CustomIcon";
import {
  signIn,
  showLoading,
  showAuthMessage,
  hideAuthMessage,
  signInWithGoogle,
  signInWithFacebook,
} from "store/slices/authSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticated } from "store/slices/authSlice";
import { motion } from "framer-motion";
import axios from "axios";
import service from "auth/FetchInterceptor";
import { AUTH_TOKEN, USER_DATA, USER_DATA_TOKEN } from "constants/AuthConstant";

import { APP_PREFIX_PATH } from "configs/AppConfig";
import { lOGIN_BANNER } from "constants/ColumnConstants";
import useNotificationWithCooldown from "../../../utils/hooks/useNotificationWithCooldown";

import Footer from "components/layout-components/Footer";
export let userContext = createContext({
  user: "",
  user_id: "",
});
export default function LoginMobile() {
  const navigate = useNavigate();
  const [inputerror, setinputError] = useState(null);
  const [title, setTitle] = useState("Send OTP");
  const [phone, setPhone] = useState("");
  const [login, setLogin] = useState({
    phone: "",
    otp: "",
    email: "",
  });
  const [otpSent, setOtpSent] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [error, setError] = useState(null);
  const [role, setRole] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useContext(userContext);
  const [countdown, setCountdown] = useState(60);
  const [otp, setOtp] = useState("");
  const showNotificationWithCooldown = useNotificationWithCooldown();

  useEffect(() => {
    let timer;
    if (otpSent && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setOtpSent(false);
      setTitle("Resend OTP");
      setVerificationStatus("");
      setLogin((prev) => ({
        ...prev,
        otp: "",
      }));
    }
    return () => clearInterval(timer);
  }, [otpSent, countdown]);

  const numberValidation = (value) => {
    const regex = /^[6-9]\d{9}$/;
    if (!regex.test(value)) {
      return true;
    }
    return false;
  };
  const handleClick = async (e) => {
      const submitLogin = {
        phone: login.phone,
      };
      setLoading(true);
      try {
        const response = await service.post(
          `${API_ENDPOINTS.SEND_OTP}`,
          submitLogin
        );

        if (response.status === "success") {
          if (response.data.message === "Invalid Credentials.") {
            setError("Invalid Credentials.");
            setLoading(false);
            return;
          }

          message.success("OTP sent successfully.");
          setTitle("Login");
          setOtpSent(true);
          setCountdown(60);
        } else {
          setError("Failed to send OTP. Please try again.");
          setOtpSent(false);
        }
      } catch (error) {
        setError("An error occurred while sending OTP.");
        setTimeout(() => {
          setError("");
        }, 10000);
      } finally {
        setLoading(false); // Ensure loading is stopped in both success and error cases
      }
    
  };

  const handleVerifyOTP = async () => {
    if (login.otp !== "") {
      setLoading(true);

      try {
        const response = await service({
          method: "post",
          url: `${API_ENDPOINTS.LOGIN_USER}`,
          data: login,
        });
        if (response.status === "success") {
          setLoading(false);
          setVerificationStatus("Sign In successfully!");
          setTitle("Sign In");

          const user_data = response.data.user_data;
          const login_user = user_data.role;
          let newUserData = {};

          if (login_user === USER_TYPE.CLIENT) {
            newUserData = {
              ...userData,
              user: user_data.user_name,
              user_id: user_data.id,
              clientLogin_id: user_data.clientData.id,
              token: response.data.token,
              role: login_user,
              mobile: user_data.mobile,
            };
            navigate(`${APP_PREFIX_PATH}/section/myDistributor`, {
              replace: true,
            });
          } else if (login_user === USER_TYPE.DISTRIBUTOR) {
            newUserData = {
              ...userData,
              user: user_data.user_name,
              user_id: user_data.id,
              clientLogin_id: user_data.distributorData.client_id,
              distributorLogin_id: user_data.distributorData.id,
              operatorLogin_id: user_data.operatorData.id,
              token: response.data.token,
              role: login_user,
              mobile: user_data.mobile,
            };
            navigate(`${APP_PREFIX_PATH}/section/myOperator`, {
              replace: true,
            });
          } else if (login_user === "admin") {
            newUserData = {
              ...userData,
              user: login_user,
              user_id: user_data.id,
              mobile: user_data.mobile,
            };
            navigate(`${APP_PREFIX_PATH}/section/myClient`, { replace: true });
          } else if (login_user === USER_TYPE.MASTER_ADMIN) {
            newUserData = {
              ...userData,
              user: user_data.user_name,
              user_id: user_data.id,
              token: response.data.token,
              role: login_user,
              mobile: user_data.mobile,
            };
            navigate(`${APP_PREFIX_PATH}/section/myClient`, { replace: true });
          } else if (login_user === USER_TYPE.OPERATOR) {
            newUserData = {
              ...userData,
              user: user_data.user_name,
              user_id: user_data.id,
              clientLogin_id: user_data.operatorData.client_id,
              distributorLogin_id: user_data.operatorData.distributor_id,
              operatorLogin_id: user_data.operatorData.id,
              token: response.data.token,
              role: login_user,
              mobile: user_data.mobile,
            };
            navigate(`${APP_PREFIX_PATH}/info/Policy`, { replace: true });
          }

          userData.method(newUserData);
          localStorage.setItem(USER_DATA, JSON.stringify(newUserData));
          localStorage.setItem(USER_DATA_TOKEN, response.data.token);
          localStorage.setItem(AUTH_TOKEN, response.data.token);
          dispatch(authenticated(response.data.token));
        } else {
          setVerificationStatus("Sign In failed. Please try again.");
          setError("Failed to sign in. Please try again.");
        }
      } catch {
        setVerificationStatus("Sign In failed. Invalid OTP.");
      } finally {
        setLoading(false);
      }
    } else {
      showNotificationWithCooldown("Please fill OTP first");
    }
  };

  const functionOTP = () => {
    setOtpSent(false);
    setTitle("Resend OTP");
  };

  return (
    <>
      <Row gutter={0} className="w-100">
        <Col span={12} lg={12} md={12} sm={0} xs={0}>
          <img src={lOGIN_BANNER} alt="logo" className="w-100 banner_img_fit" />
        </Col>
        <Col
          span={12}
          lg={12}
          md={12}
          sm={24}
          xs={24}
          className="d-flex flex-column align-items-center"
        >
          <div className="container d-flex flex-column justify-content-center h-100 w-50">
            <img src={MyLogo} alt="Hayosha" className="w-60 logo" />

            <h1>Login</h1>
            <div className="mt-2">
              <Form layout="vertical" name="login-form">
                <Form.Item name="phone" label="Mobile No.">
                  <Input
                    disabled={otpSent ? true : false}
                    type="text"
                    prefix={<MobileOutlined className="text-primary" />}
                    value={login.phone}
                    onChange={(e) => {
                      const newPhoneValue = e.target.value;
                      setLogin((prevData) => ({
                        ...prevData,
                        phone: newPhoneValue,
                        email: newPhoneValue,
                      }));
                    }}
                  />
                  {inputerror && <p>{inputerror}</p>}
                </Form.Item>
                {otpSent ? (
                  <Form.Item name="otp" label="OTP">
                    <Input
                      prefix={<LockOutlined className="text-primary" />}
                      value={login.otp}
                      onChange={(e) => {
                        setLogin((prevData) => ({
                          ...prevData,
                          otp: e.target.value.trim(),
                        }));
                      }}
                    />
                  </Form.Item>
                ) : (
                  ""
                )}
              </Form>

              <Form.Item>
                <Button
                  loading={loading}
                  disabled={login.phone.length == 10 ? false : true}
                  type="primary"
                  htmlType="submit"
                  block
                  onClick={
                    title == "Send OTP" || title == "Resend OTP"
                      ? handleClick
                      : handleVerifyOTP
                  }
                >
                  {title}
                </Button>
                {otpSent && <p>Resend OTP available in {countdown} seconds.</p>}
                <h5>
                  {verificationStatus && <p>{verificationStatus}</p>}
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </h5>
              </Form.Item>
              <Form.Item>
                <span>
                  <h4>
                    <a href="/auth/login-2">
                      <u>Sign in with User ID</u>
                    </a>
                  </h4>
                </span>
              </Form.Item>
            </div>
          </div>
          <Footer footer_align={"footer-align"} />
        </Col>
      </Row>
    </>
  );
}
