import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const ActiveMenu = () => {
    const location = useLocation();

    const { menuId } = useParams();

    useEffect(() => {
        const menuItemLink = location.pathname;
        const menuItems = document.querySelectorAll('.ant-menu-item');

        menuItems.forEach((item) => {
            const link = item.querySelector('a');
            if (link && link.getAttribute('href') === menuItemLink) {
                item.classList.add('ant-menu-item-active', 'ant-menu-item-selected');
            } else {
                item.classList.remove('ant-menu-item-active', 'ant-menu-item-selected');
            }
        });
    }, [location]);

    return null;
};

export default ActiveMenu;
