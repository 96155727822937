export const API_ENDPOINTS = {
  // FOR LOGIN
  SEND_OTP: `/user/api/v1/send_otp/`,
  LOGIN_USER: `/user/api/v1/login_user`,
  VERIFY_OTP: `/user/api/v1/verify_otp/`,

  // FOR FORGOT PASSWORD
  FORGOT_PASSWORD: `/user/api/v1/forgot_password/`,

  // FOR CLIENT
  GET_CLIENT_LIST: `/client/api/v1/get_client_list/`,
  GET_CLIENT: `/client/api/v1/get_client/`,
  CREATE_CLIENT: `/client/api/v1/create_client/`,
  UPDATE_CLIENT: `/client/api/v1/update_client/`,

  // FOR DISTRIBUTOR
  GET_DISTRIBUTOR: `/distributor/api/v1/get_distributor/`,
  GET_DISTRIBUTOR_LIST: `/distributor/api/v1/get_distributor_list/`,
  CREATE_DISTRIBUTOR: `/distributor/api/v1/create_distributor/`,
  UPDATE_DISTRIBUTOR: `/distributor/api/v1/update_distributor/`,
  GET_DATA_USING_PINCODE: `/metadata/api/v1/get_data_using_pincode/`,

  // FOR OPERATOR
  CREATE_DOCUMENT: `/document/api/v1/create_document/`,
  GET_OPERATOR_LIST: `/operator/api/v1/get_operator_list/`,
  CREATE_OPERATOR: `/operator/api/v1/create_operator/`,
  GET_OPERATOR: `/operator/api/v1/get_operator/`,
  UPDATE_OPERATOR: `/operator/api/v1/update_operator/`,

  // FOR SUPERVISOR
  GET_SUPERVISOR_DATA: `/distributor/api/v1/get_supervisor_data/`,
  GET_LINE_OF_BUSINESS_OPTIONS: `/metadata/api/v1/get_line_of_business_options/`,
  GET_STATE_OPTIONS: `/metadata/api/v1/get_state_options/`,

  // FOR POLICY DETAILS AND MY POLICY
  GET_POLICY_LIST: `/policy/api/v1/get_policy_list/`,
  GET_POLICY: `/policy/api/v1/get_policy/`,
  UPDATE_POLICY: `/policy/api/v1/update_policy/`,
  CREATE_USER: `/user/api/v1/create_user/`,

  // FOR INSURANCE COMPANY
  GET_INSURANCE_COMPANY_LIST: `/insurance_company/api/v1/get_insurance_company_list/`,
  GET_INSURANCE_COMPANY: `/insurance_company/api/v1/get_insurance_company/`,
  CREATE_INSURANCE_COMPANY: `/insurance_company/api/v1/create_insurance_company/`,
  UPDATE_INSURANCE_COMPANY: `/insurance_company/api/v1/update_insurance_company/`,

  // FOR PRODUCT
  GET_PRODUCT_LIST: `/product/api/v1/get_product_list/`,
  GET_PRODUCT: `/product/api/v1/get_product/`,
  CREATE_PRODUCT: `/product/api/v1/create_product/`,
  UPDATE_PRODUCT: `/product/api/v1/update_product/`,

  // FOR PACKAGE
  GET_PACKAGE_LIST: `/package/api/v1/get_package_list/`,
  GET_PACKAGE: `/package/api/v1/get_package/`,
  CREATE_PACKAGE: `/package/api/v1/create_package/`,
  UPDATE_PACKAGE: `/package/api/v1/update_package/`,
  ASSIGN_PACKAGE: `/package/api/v1/assign_package/`,
  DELETE_ASSIGN_PACKAGE: `/package/api/v1/delete_assign_package/`,

  // FOR ADDITIONAL METADATA
  GET_MARITAL_STATUS_OPTIONS: `/metadata/api/v1/get_marital_status_options`,
  GET_RELATIONSHIP_TYPES_OPTIONS: `/metadata/api/v1/get_relationship_types_options`,
  GET_GENDER_OPTIONS: `/metadata/api/v1/get_gender_options`,
  GET_DOCUMENT_TYPES_OPTIONS: `/metadata/api/v1/get_document_types_options`,
  GET_OCCUPATION_OPTIONS: `/metadata/api/v1/get_occupation_options/`,

  // FOR IMPORT AND PREMIUM
  IMPORT_DISTRIBUTOR: `/distributor/api/v1/import_distributor/`,
  PAYMENT_INTEGRATION_CHECKOUT: `/payment_integration/api/v1/checkout`,

  CREATE_POLICY: `/policy/api/v1/create_policy`,
  PAYMENT_VERIFICATION: `/payment_integration/api/v1/payment_verification`,

  DELETE_DOCUMENT: `/document/api/v1/delete_document/`,
  UPDATE_DOCUMENT: `/document/api/v1/update_document/`,
  PAYMENT_FG: `/payment_integration/api/v1/fg/payment_checkout/`,

  GET_CIRCLE_LIST: `/circle/api/v1/get_circle_list/`,
  GET_CIRCLE: `/circle/api/v1/get_circle/`,
  CREATE_CIRCLE: `/circle/api/v1/create_circle/`,
  UPDATE_CIRCLE: `/circle/api/v1/update_circle/`,
  DELETE_CIRCLE: `/circle/api/v1/delete_circle/`,
};
