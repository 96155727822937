import { useState, useCallback } from 'react';
import { message } from 'antd';

let notificationTimer = null; 
const notificationCooldown = 5000; 

const useNotificationWithCooldown = () => {
 
  const showNotificationWithCooldown = useCallback((description) => {
    if (!notificationTimer) {
      message.error(description);
      notificationTimer = setTimeout(() => {
        notificationTimer = null; // Reset the timer after cooldown
      }, notificationCooldown);
    }
  }, []);

  return showNotificationWithCooldown;
};

export default useNotificationWithCooldown;
