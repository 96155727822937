export const ERROR_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  TIMEOUT: 508,
  OTHER: 600,
};

export const ERROR_MESSAGES = {
  AUTHENTICATION_FAILED: "Authentication Failed",
  LOGIN_AGAIN: "Please log in again.",
  NOT_FOUND: "Not Found",
  RESOURCE_NOT_FOUND: "The requested resource could not be found.",
  SERVER_ERROR: "Internal Server Error",
  SERVER_ISSUE: "An error occurred on the server. Please try again later.",
  TIMEOUT: "Time Out",
  REQUEST_TIMEOUT: "The request timed out. Please try again.",
  GENERIC_ERROR: "Error",
  UNEXPECTED_ERROR: "An unexpected error occurred. Please try again.",
  NO_RESPONSE: "Network error or no response from server. Retrying...",
};

export const TOKEN_PAYLOAD_KEY = "AccessToken";
export const AUTH_TOKEN = "auth_token";
