import { CookiesProvider } from "react-cookie";
import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import "./index.css";
import { userContext } from "views/auth-views/components/loginMobile";
import { LoginContext } from "views/auth-views/components/loginEmail";
import { MyContext, distributorIDContext, clientIDContext } from "views/app-views/section/context";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};
function App() {
  const [user, setUser] = useState({
    user: "",
    user_id: "",
  });
  const [userData, setUserData] = useState({
    userClient: "",
  });
  const [distributorID, setDistributorID] = useState({
    distributor_id: "",
  });
  const [clientID, setClientID] = useState({
    client_id: "",
  });
  // const [user_id, setUser_id] = useState("");
  return (
    <div className="App">
      <clientIDContext.Provider
        value={{ client_id: clientID, method: setClientID }}
      >
      <MyContext.Provider value={{ user: userData, method: setUserData }}>
        <distributorIDContext.Provider
          value={{ distributor_id: distributorID, method: setDistributorID }}
        >
          <LoginContext.Provider value={{ user: user, method: setUser }}>
            <userContext.Provider value={{ user: user, method: setUser }}>
              <CookiesProvider>
                <Provider store={store}>
                  <BrowserRouter history={history}>
                    <ThemeSwitcherProvider
                      themeMap={themes}
                      defaultTheme={THEME_CONFIG.currentTheme}
                      insertionPoint="styles-insertion-point"
                    >
                      <Layouts />
                    </ThemeSwitcherProvider>
                  </BrowserRouter>
                </Provider>
              </CookiesProvider>
            </userContext.Provider>
          </LoginContext.Provider>
        </distributorIDContext.Provider>
      </MyContext.Provider>
      </clientIDContext.Provider>
    </div>
  );
}

export default App;
