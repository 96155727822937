import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { signOutSuccess } from "store/slices/authSlice";
import store from "../store";
import { notification } from "antd";
import {
  ERROR_CODES,
  ERROR_MESSAGES,
  TOKEN_PAYLOAD_KEY,
  AUTH_TOKEN,
} from "constants/ErrorConstants";
import { USER_DATA } from "constants/AuthConstant";

const unauthorizedCode = [ERROR_CODES.UNAUTHORIZED, ERROR_CODES.FORBIDDEN];

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

let notificationTimer = null;
const notificationCooldown = 5000;

const showNotificationWithCooldown = (message, description) => {
  if (!notificationTimer) {
    notification.error({
      message,
      description,
    });

    notificationTimer = setTimeout(() => {
      notificationTimer = null;
    }, notificationCooldown);
  }
};

service.interceptors.request.use(
  (config) => {
    config.method = config.method.toLowerCase();
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (
      config.url.includes("send_otp") ||
      config.url.includes("login_user") ||
      config.url.includes("verify_otp") ||
      config.url.includes("forgot_password")
    ) {
      return config;
    } else {
      const role = localStorage.getItem(USER_DATA);

      if (!role) {
        localStorage.clear();
        store.dispatch(signOutSuccess());

        showNotificationWithCooldown(
          ERROR_MESSAGES.AUTHENTICATION_FAILED,
          ERROR_MESSAGES.LOGIN_AGAIN
        );
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },

  async (error) => {
    const { config, response } = error;

    if (!response) {
      showNotificationWithCooldown(
        ERROR_MESSAGES.NO_RESPONSE,
        ERROR_MESSAGES.CHECK_NETWORK
      );
      return Promise.reject(error);
    }
    const { status, data } = response;

    if (config.method === "get") {
      if (!config.__retryCount) {
        config.__retryCount = 0;
      }

      const maxRetries = 1;
      if (config.__retryCount < maxRetries) {
        config.__retryCount += 1;

        await new Promise((resolve) => setTimeout(resolve, 5000));
        return service(config);
      }
    }
    let notificationParam = { message: "", description: "" };

    if (unauthorizedCode.includes(status)) {
      notificationParam.message = ERROR_MESSAGES.AUTHENTICATION_FAILED;
      notificationParam.description = ERROR_MESSAGES.LOGIN_AGAIN;
      localStorage.clear();
      store.dispatch(signOutSuccess());
    } else if (status === ERROR_CODES.NOT_FOUND) {
      notificationParam.message = ERROR_MESSAGES.NOT_FOUND;
      notificationParam.description = ERROR_MESSAGES.RESOURCE_NOT_FOUND;
    } else if (status === ERROR_CODES.INTERNAL_SERVER_ERROR) {
      notificationParam.message = ERROR_MESSAGES.SERVER_ERROR;
      notificationParam.description = ERROR_MESSAGES.SERVER_ISSUE;
    } else if (status === ERROR_CODES.TIMEOUT) {
      notificationParam.message = ERROR_MESSAGES.TIMEOUT;
      notificationParam.description = ERROR_MESSAGES.REQUEST_TIMEOUT;
    } else {
      notificationParam.message = ERROR_MESSAGES.GENERIC_ERROR;
      notificationParam.description = ERROR_MESSAGES.UNEXPECTED_ERROR;
    }

    showNotificationWithCooldown(
      notificationParam.message,
      notificationParam.description
    );
    return Promise.reject(error);
  }
);

export default service;
