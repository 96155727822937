export const USER_TYPE = {
    MASTER_ADMIN : 'master_admin',
    CLIENT : 'client',
    DISTRIBUTOR:'distributor',
    OPERATOR:'operator'
}

export const REDIRECT_PATHS = {
   CLIENT_PATH: '/section/myClient',
   DISTRIBUTOR_PATH: '/section/myDistributor',
   OPERATOR_PATH: '/section/myOperator',
   POLICY_PATH: '/info/Policy',
   MY_POLICY_PATH: '/info/MyPolicyDetails',
};
