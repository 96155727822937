import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  
} from "configs/AppConfig";
import navigationConfig, { exceptNav } from "configs/NavigationConfig";
import NotFound from "NotFound";
import { USER_TYPE, REDIRECT_PATHS } from "constants/UserConstant";
import { USER_DATA } from "constants/AuthConstant";

const getAllowedRoutesForRole = (role) => {
  const allowedRoutes = [];
  exceptNav.map((navItem) => {
    if (
      navItem.role &&
      Array.isArray(navItem.role) &&
      navItem.role.includes(role)
    ) {
      allowedRoutes.push(navItem.path);
    }
  });
  navigationConfig.map((navItem) => {
    if (
      navItem.role &&
      Array.isArray(navItem.role) &&
      navItem.role.includes(role)
    ) {
      allowedRoutes.push(navItem.path);

      navItem.submenu.map((subItem) => {
        if (
          subItem.role &&
          Array.isArray(subItem.role) &&
          subItem.role.includes(role)
        ) {
          allowedRoutes.push(subItem.path);
        }
      });
    }
  });
  return allowedRoutes;
};

const getRedirectPathForRole = (role) => {
  switch (role) {
    case USER_TYPE.MASTER_ADMIN:
      return `${APP_PREFIX_PATH}${REDIRECT_PATHS.CLIENT_PATH}`;
    case USER_TYPE.CLIENT:
      return `${APP_PREFIX_PATH}${REDIRECT_PATHS.DISTRIBUTOR_PATH}`;
    case USER_TYPE.DISTRIBUTOR:
      return `${APP_PREFIX_PATH}${REDIRECT_PATHS.OPERATOR_PATH}`;
    case USER_TYPE.OPERATOR:
      return `${APP_PREFIX_PATH}${REDIRECT_PATHS.POLICY_PATH}`;
    default:
      return "/"; 
  }
};

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const userData = JSON.parse(localStorage.getItem(USER_DATA)) || {}; 
  const location = useLocation();

  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}`}
        replace={false}
      />
    );
  }
  const allowedRoutes = getAllowedRoutesForRole(userData.role);

  if (location.pathname === "/") {
    const redirectPath = getRedirectPathForRole(userData.role);
    return <Navigate to={redirectPath} replace />;
  }

  if (!allowedRoutes.includes(location.pathname)) {
    return <NotFound />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
export {getRedirectPathForRole};
